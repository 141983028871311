<template>
  <div>
    <Card>
      <template #body>
        <Table>
          <template #email="{ item }">
            <span v-if="item.user">
              <a class="ref-decoration" :href="`mailto:${item.user.email}`">
                {{ item.user.email }}
              </a>
            </span></template
          >
        </Table>
      </template>
      <!-- <template #table-column="{ item2 }">
        <v-chip
          outlined
          class="ma-2"
          :text-color="item2.status != 1 ? 'red' : 'green'"
          :color="item2.status != 1 ? 'red' : 'green'"
        >
          {{ item2.status_text }}
        </v-chip>
      </template> -->
    </Card>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Card from "../Components/Card.vue";
import Table from "../Components/Table.vue";
export default {
  name: "Dashboard",
  components: {
    Card,
    Table,
  },
  data() {
    return {
      btns: [
        // {
        //   type: "icon",
        //   text: "view_user",
        //   color: "bg-gradient-success",
        //   icon: "mdi-eye",
        //   item: true,
        //   url: "users/view/",
        //   permission: "user-view",
        // },
        {
          type: "icon",
          text: "edit",
          color: "bg-gradient-success",
          icon: "mdi-pencil",
          url: "contractors/edit/",
          item: true,
          permission: "contractors-edit",
        },
        {
          type: "icon",
          text: "delete_contractor",
          color: "bg-gradient-success",
          icon: "mdi-delete",
          permission: "contractors-delete",
        },
      ],
      header: [
        {
          text: this.$i18n.t("Company Name"),
          value: "company_name",
          align: "center",
        },
        {
          text: this.$i18n.t("contractors.Contractor Name"),
          value: "name",
          align: "center",
        },
        {
          text: this.$i18n.t("auth.Phone Number"),
          value: "phone",
          align: "center",
        },
        {
          text: this.$i18n.t("auth.Email Address"),
          value: "user",
          align: "center",
          name: "email",
        },
        {
          text: this.$i18n.t("contractors.mobile"),
          value: "mobile",
          align: "center",
        },

        // {
        //   text: this.$i18n.t("status"),
        //   value: "column",
        //   align: "center",
        // },
        { text: this.$i18n.t("Action"), value: "btns", align: "center" },
      ],
      card: {
        title: this.$i18n.t("contractors.contractors"),
        add_url: "/contractors/create",
        permission: "contractors-create",
      },
      load_excel: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["str_per"]),
    classLangBtn() {
      return {
        "mr-6": this.$vuetify.rtl,
        "ml-6": !this.$vuetify.rtl,
      };
    },
  },
  methods: {
    ...mapActions("user", ["activeAccount", "disabledAcctount"]),

    classBtn(val) {
      // console.log("val_class "+val)
      return this.$store.commit("table/SET_CHECK_PER", val);
    },
    set_data() {
      this.$store.commit("SET_CARD", this.card);
      this.$store.commit("SET_COLLECTION", "user");
      this.$store.commit("table/SET_LOADING", true);
      this.$store.commit("table/SET_HEADERS", this.header);
      this.$store.commit("table/SET_BTNS", this.btns);
      this.$store.commit("table/SET_PAGINATION", true);
      this.$store.commit("SET_URL", "contractors");
    },

    active(item) {
      // console.log(item);
      this.$swal({
        title: this.$i18n.t("general.Are you sure?"),
        text: this.$i18n.t("You won't be able to revert this!"),
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: this.$i18n.t("Yes"),
        cancelButtonText: this.$i18n.t("general.No, cancel!"),
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.activeAccount(item).then(
            (response) => {
              this.$swal.fire(
                this.$i18n.t("Done!"),
                response.data.message,
                "success"
              );
            },
            (error) => {
              this.$swal.fire(
                this.$i18n.t("Error"),
                this.$i18n.t("There error please try again"),
                "error"
              );
            }
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === this.$swal.DismissReason.cancel
        ) {
          this.$swal.fire(
            this.$i18n.t("general.Cancelled"),
            this.$i18n.t("general.Cancelled Delete"),
            "error"
          );
        }
      });
    },
  },
  created() {
    console.log(this.$route.params.message);
    if (this.$route.params.message) {
      this.$store.commit("form/SET_NOTIFY", this.$route.params.message);
    }
  },
  mounted() {
    this.set_data();
    this.$store.dispatch("user/getData", { reset: true });

    document.title = this.$i18n.t("contractors.contractors");
  },
};
</script>
